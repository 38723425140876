.tmap .highlight {
  content: url("../static/img/tmap/arrow_yellow.png") !important;
}

.tmap .active {
  content: url("../static/img/tmap/arrow.png");
  /* add margin to set marker in right spot */
  margin-top: 40px !important;
  margin-left: 10px !important;
  height: 15px !important;
  width: 15px !important;
  z-index: 10;
  display: inline-block;
}

.tmap .inactive {
  content: url("../static/img/tmap/pin_stopped.png");
  z-index: 1;
  filter: grayscale(100%);
  /* add margin to set marker in right spot */
  margin-top: 40px !important;
  margin-left: 10px !important;
  height: 15px !important;
  width: 15px !important;
  transform: none !important;
}

.tmap .stationary {
  content: url("../static/img/tmap/pin_stationary.png");
  z-index: 15;
  /* add margin to set marker in right spot */
  margin-top: 40px !important;
  margin-left: 10px !important;
  height: 15px !important;
  width: 15px !important;
  transform: none !important;
}

.tmap .highlight-stationary {
  content: url("../static/img/tmap/pin_stationary_yellow.png");
}
