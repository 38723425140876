
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700;800&display=swap');

body {
  font-size: 1.05rem;
}

nav {
  background: #000000;
}

.bg-hero {
  background-color: transparent !important;
  background-image: none;
}

.stream {
  margin-top: 40px;
  min-height: 900px;
}

@media (max-width: 900px) {
  .stream {
    min-height: 1000px;
  }
}

@media (max-width: 500px) {
  .stream {
    min-height: 750px;
  }
}

#company-title {
  font-size: 8vw;
  letter-spacing: 5px;
  width: 100%;
  position: relative;
  left: 0%;
  margin-top: 6rem;
}

#company-slogan {
  padding-top: 0rem !important;
}

.logo {
  width: 12vw;
  min-width: 80px;
  height: auto;
}

#working-photo-list {
  align-items: center;
}

#working-photo-list img {
  margin: 4px;
  max-width: calc((100% - 16px) / 2);
}

#investors-list {
  align-items: center;
}

#investors-list img {
  max-width: calc((100% - (0.5rem * 8)) / 2);
  width: 196px;
  margin: 0.5rem;
  height: 50px;
  object-fit: contain;
}

.features-list {
  text-indent: -24.5px;
  padding-left: 24.5px;
  left: -5px;
}

.features-list li {
  width: 100%;
  padding-left: 1.5rem;
  font-size: 1.05rem;
}

h2, .h2 {
  font-size: 1.5rem;;
}
h3, .h3 {
  font-size: 1.25rem;;
}
h4, .h4 {
  font-size: 1.15rem;;
}

/* larger than col-md */
@media (min-width: 768px) {

  #company-title {
    font-size:65px;
  }

  #working-photo-list img {
    margin: 10px;
    max-width: calc((100% - 60px) / 3);
  }

  #investors-list img {
    margin: 10px;
    width: calc((100% - 80) / 4);
  }

  h2, .h2 {
    font-size: 2.5rem;
  }
  h3, .h3 {
    font-size: 1.5rem;;
  }
  h4, .h4 {
    font-size: 1.375rem;;
  }

  .features-list {
    left: 10px;
  }

  .features-list li {
    width: calc((100% - (10px + 40px)) / 2);
    margin-right: 20px;
  }

}

/* larger than col-lg */
@media (min-width: 992px) {
  #soma-logo {
    left: 25px;
  }
  #benefits-title {
    position: relative;
    left: 2.5rem;
  }
}
.datebox {
  border:1px solid white;
  border-radius: 5px;
  width: 60px;
  height: 60px;
}

.heading-orange {
  color: #F37021;
  font-weight: 800; 
}

.orange {
  color: #F37021;; 
}

.heading-gray {
  color: rgb(93, 194, 208);
  font-weight: 800;
}

.gray {
  color: rgb(93, 194, 208);
}

.white-text {
  color: white;
  font-weight: 600;
}

.bg-bottom {
  background-color: #404040; 
}

header {
  position: relative;
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}


header video {
  position: absolute;
  top: 52%;
  left: 50%;
  width: 90% !important;
  height: 90% !important;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

header .container {
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  header  {
    height: 60vh;
  }
}

