.map-container {
  width: 100%;
  position: relative;
  background-color: yellow;
  background-color: rgb(255, 255, 255, 0.08);
  border-radius: 10px;
  padding: 10px;
  height: 800px;
}

.map-checkbox-container {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  column-gap: 5px;
  background-color: black;
}
.map-checkbox {
  background-color: rgb(0, 0, 0, 0.7);
  position: absolute;
  left: 10px;
  padding-right: 5px;
}

@media (max-width: 1200px) {
  .map-container {
    display: flex;
    flex-grow: 1;
    margin-left: 0;
    /* max-height: 640px; */
  }
}

@media (max-width: 990px) {
  .map-container {
    display: flex;
    flex-grow: 1;
    margin-left: 0;
    height: 680px;
    /* max-height: 640px; */
  }
}

@media (max-width: 770px) {
  .map-container {
    width: 100%;
    margin-left: 0;
    height: 680px;
  }
}
